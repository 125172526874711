<template>
  <div class="microcart-offer v2" v-if="isApplicable && salesRules.length > 0">
    <div class="microcart-offer-content">
      <div class="microcart-offer-subhead">
        Gratis-Aktion
      </div>

      <div class="microcart-offer-head">
        Attraktive Geschenke ab {{ salesRules[0].conditionFromValue }} € Bestellwert
      </div>

      <div class="microcart-offer-content-collapse active">
        <p>{{ salesRules[0].description }}</p>
      </div>
    </div>

    <div class="microcart-offer-products">
      <div class="row">
        <div v-for="rule in salesRules" :key="rule.rule_id" :class="'col-xs-4 ' + getRuleConfig(rule).slug">
          <div class="microcart-offer-products__savings">
            <span>im Wert</span> <span>von</span> <span class="price">{{ getRuleProductPrice(rule) }}</span>
          </div>
          <img v-lazy="getRuleConfig(rule).image">
          <div class="microcart-offer-products__details">
            <div class="microcart-offer-products__name">
              {{ rule.name }}
            </div>
            <div class="microcart-offer-products__price">
              ab {{ rule.conditionFromValue }}€
            </div>
            <div class="microcart-offer-products__order-value">
              Bestellwert
            </div>
          </div>
          <div class="microcart-offer-products__actions" v-if="getRuleProduct(rule)">
            <add-to-cart :product="getRuleProduct(rule)" v-if="getRuleProduct(rule).type_id === 'simple'" class="btn btn-secondary btn-add-to-cart">
              Hinzufügen
            </add-to-cart>
            <router-link :to="getRuleProductLink(rule)" @click.native="goToProduct(getRuleProductLink(rule))" v-if="getRuleProduct(rule).type_id != 'simple'" class="btn btn-secondary btn-add-to-cart">
              Hinzufügen
            </router-link>
            <router-link :to="getRuleProductLink(rule)" @click.native="goToProduct(getRuleProductLink(rule))" class="microcart-offer-products__product-link">
              Zum Produkt
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Offer v1 (Gartenkamin) -->
  <!--div class="microcart-offer" v-if="isApplicable && salesRule">
    <div class="microcart-offer-content">
      <div class="microcart-offer-subhead">
        {{ salesRule.name }}
      </div>

      <div class="microcart-offer-head">
        Gartenkamin geschenkt ab {{ conditionFromValue }} &euro; Bestellwert
      </div>

      <div class="microcart-offer-content-collapse" :class="{ active: isCollapse }">
        <p>{{ salesRule.description }}</p>
      </div>

      <div class="microcart-offer-more" :class="{ active: isCollapse }">
        <a class="btn btn-secondary btn-more" @click="toggleSpecial">Mehr erfahren</a>
        <a class="btn btn-blank btn-toggle" @click="toggleSpecial">Nein, Danke!</a>
        <add-to-cart :product="product" class="btn btn-secondary btn-toggle">
          Jetzt hinzufügen
        </add-to-cart>
      </div>
    </div>
    <div class="microcart-offer-image">
      <img src="/assets/content/cart-special-offer.png">
    </div>
  </div-->
</template>

<script>
import { mapGetters } from 'vuex'
import AddToCart from 'theme/components/core/AddToCart'
import { SearchQuery } from 'storefront-query-builder'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

export default {
  components: {
    AddToCart
  },
  data () {
    return {
      isCollapse: false,
      products: [],
      rules: [{
        rule_id: 64,
        image: '/assets/content/cart-special-offer-light-dark.png',
        slug: 'gardenlight'
      },
      {
        rule_id: 63,
        image: '/assets/content/cart-special-offer-terracelight.png',
        slug: 'terracelight'
      },
      {
        rule_id: 46,
        image: '/assets/content/cart-special-offer.png',
        slug: ''
      }]
    }
  },
  computed: {
    ...mapGetters({
      storeConfig: 'storeConfig/getConfigValue',
      productsInCart: 'cart/getCartItems'
    }),
    salesRules () {
      return this.rules
        .map((_rule) => {
          let rule
          try {
            const json = this.storeConfig('salesrule/rule/' + _rule.rule_id)
            rule = JSON.parse(json)
          } catch (e) {
            return null
          }
          rule.conditionFromValue = rule.conditions_serialized.conditions[0].value - rule.discount_amount;
          // rule.freeProductSku = rule.actions_serialized.conditions[0].value

          const productFoundRule = rule.conditions_serialized.conditions.find(c => c.type === 'Magento\\SalesRule\\Model\\Rule\\Condition\\Product\\Found')
          const productFoundCondition = (productFoundRule) ? productFoundRule.conditions.find(c => c.attribute === 'sku') : null
          rule.freeProductSku = (productFoundCondition) ? productFoundCondition.value : null
          return rule
        }).filter((r) => r !== null).sort((a, b) => a.conditionFromValue - b.conditionFromValue)
    },
    isApplicable () {
      return this.productsInCart.filter((product) => product.sku && product.sku.match('^angebot_')).length === 0
    },
    storeView () {
      return currentStoreView()
    }
  },
  created () {
    this.loadDiscountProducts()
  },
  methods: {
    getRuleProductLink (rule) {
      const product = this.getRuleProduct(rule)
      if (product) {
        return formatProductLink(product, currentStoreView().storeCode)
      }
    },
    toggleSpecial () {
      this.isCollapse = !this.isCollapse;
    },
    async loadDiscountProducts () {
      const skus = this.salesRules.map(rule => rule.freeProductSku).filter(n => n)

      if (skus.length === 0) {
        return;
      }

      let searchQuery = new SearchQuery()
      searchQuery = searchQuery.applyFilter({ key: 'sku', value: { in: skus } })

      await this.$store.dispatch('product/findProducts', { query: searchQuery, start: 0, size: 3, updateState: false }).then((resp) => {
        if (resp.items.length >= 1) {
          this.products = resp.items
        }
      })
    },
    getRuleConfig (rule) {
      return this.rules.find(r => r.rule_id === parseInt(rule.rule_id)) || {}
    },
    getRuleProduct (rule) {
      if (!this.products) {
        return
      }
      return this.products.find(product => {
        const sku = this.salesRules.find(r => r.rule_id === rule.rule_id).freeProductSku
        return sku === product.parentSku || sku === product.sku
      })
    },
    getRuleProductPrice (rule) {
      return Number(rule.discount_amount).toFixed(0) + '€'
    },
    goToProduct (link) {
      this.$router.push(localizedRoute(link, currentStoreView().storeCode))
      this.$store.dispatch('ui/toggleMicrocart')
      this.$store.commit('ui/setSidebar', false)
    }
  }
}
</script>
