<template>
  <div
    class="microcart cl-accent relative"
    :class="[productsInCart.length ? 'bg-cl-secondary' : 'bg-cl-primary', cartUpdating ? 'cart-updating' : '']"
    data-testid="microcart"
    id="microcart"
  >
    <span v-if="cartUpdating === true" class="loading-mask"><span class="loader" /></span>
    <transition name="fade">
      <div v-if="isEditMode" class="overlay" @click="closeEditMode" />
    </transition>
    <div class="microcart-inner">
      <div class="microcart-close">
        <button
          type="button"
          class="close"
          @click="closeMicrocartExtend"
          data-testid="closeMicrocart"
        />
      </div>

      <div class="microcart-head">
        <logo width="auto" height="32px" />
        {{ $t('Warenkorb') }}
      </div>

      <div class="microcart-items">
        <div class="microcart-empty" v-if="!productsInCart.length" @click="closeMicrocartExtend">
          {{ $t('Your shopping cart is empty.') }}
        </div>

        <div v-if="productsInCart.length">
          <product
            v-for="product in productsInCart"
            :key="product.server_item_id || product.id"
            :product="product"
            @cart-updating="cartUpdating = true"
            @cart-updated="cartUpdating = false"
          />
        </div>
      </div>

      <div class="microcart-summary" v-if="productsInCart.length">
        <div v-if="productsInCart.length">
          <div
            v-for="(segment, index) in totals" :key="index" :class="'row ' + segment.code"
            v-if="segment.code !== 'grand_total' && segment.value !== null"
          >
            <div class="col col-xs-8">
              <span v-if="segment.code === 'tax' && platformTotals.total_segments">
                {{ taxTotalsTitle || 'inkl. MwSt.' }}
              </span>
              <span v-else>
                {{ $t(segment.title) }}
              </span>

              <button v-if="appliedCoupon && segment.code === 'discount'" type="button" class="p0 brdr-none bg-cl-transparent close delete-button ml10" @click="clearCoupon">
                <i class="material-icons cl-accent">
                  close
                </i>
              </button>
            </div>
            <div v-if="segment.code === 'shipping' && platformTotals.shipping_incl_tax" class="col col-xs-4 align-right">
              {{ platformTotals.shipping_incl_tax | price }}
            </div>
            <div v-else-if="segment.value != null" class="col col-xs-4 align-right">
              {{ segment.value | price }}
            </div>
          </div>
          <div class="microcart-summary-tax head-sm">
            <div class="row" v-for="(segment, index) in totals" :key="index" v-if="segment.code === 'grand_total'">
              <div class="col col-xs-7 total-price-label">
                {{ segment.title }}
              </div>
              <div class="col col-xs-5 align-right total-price-value">
                {{ segment.value | price }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="microcart-options" v-if="productsInCart.length">
        <ul>
          <share-cart />
          <li class="microcart-options-print">
            <a href="#" onclick="window.print('microcart');return false;">
              <img
                src="/assets/icon/icon-print.svg"
              >
              Warenkorb drucken
            </a>
          </li>
          <li class="microcart-options-clear">
            <clear-cart-button
              v-if="productsInCart.length"
              @click.native="clearCart"
            />
          </li>
          <li class="microcart-options-coupon">
            <div v-if="OnlineOnly && !addCouponPressed" class="microcart-actions-checkout">
              Aktionscode oder Gutschein?
              <a
                @click="addDiscountCoupon"
              >
                {{ $t('Redeem here') }}
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="OnlineOnly && addCouponPressed" class="microcart-coupon-wrapper">
        <div class="coupon-input">
          <label>{{ $t('Discount code') }}</label>
          <base-input type="text" id="couponinput" :autofocus="true" v-model.trim="couponCode" @keyup.enter="setCoupon" />
        </div>
        <button-outline color="dark" :disabled="!couponCode" @click.native="setCoupon">
          {{ $t('Add discount code') }}
        </button-outline>
      </div>

      <div v-if="isCheckoutDisabled" class="notifications">
        <div class="notification error">
          {{ storeConfig('sales/minimum_order/error_message') }}
        </div>
      </div>

      <div
        class="microcart-actions"
        v-if="productsInCart.length && !isCheckoutMode"
      >
        <button-full
          :disabled="isCheckoutDisabled"
          :link="{ name: 'checkout', params: { paypal: true } }"
          @click.native="closeMicrocartExtend"
          class="microcart-actions-paypal"
        >
          Sofort bezahlen mit&nbsp;
          <img
            src="/assets/logo/logo-paypal.svg"
          >
        </button-full>

        <div class="microcart-actions-divider">
          oder
        </div>

        <button-full
          :disabled="isCheckoutDisabled"
          :link="{ name: 'checkout' }"
          @click.native="closeMicrocartExtend"
          class="btn btn-primary btn-blue btn-checkout"
        >
          {{ $t('Go to checkout') }}
        </button-full>
        <!--instant-checkout v-if="isInstantCheckoutRegistered" class="" / -->

        <!-- <div class="col-xs-12 col-sm first-sm">
          <router-link :to="localizedRoute('/')" class="no-underline cl-secondary link">
            <span @click="closeMicrocartExtend">
              {{ $t('Return to shopping') }}
            </span>
          </router-link>
        </div>
        -->
      </div>

      <sales-rule-offer />
      <microcart-banners :id="13" />

      <div class="microcart-footer">
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="footer-header">Zahlungsarten</div>
            <footer-trust-payment-methods />
          </div>
          <div class="col-xs-12 col-md-12">
            <div class="footer-header">Rechtliches</div>
            <ul>
              <li><a href="/zahlungsarten">Zahlungsarten</a></li>
              <li><a href="/datenschutz">Datenschutz</a></li>
              <li><a href="/impressum">Impressum</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import i18n from '@vue-storefront/i18n'
import { isModuleRegistered, registerModule } from '@vue-storefront/core/lib/modules'

import VueOfflineMixin from 'vue-offline/mixin'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import InstantCheckout from 'src/modules/instant-checkout/components/InstantCheckout.vue'

import Logo from 'theme/components/core/Logo'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ClearCartButton from 'theme/components/core/blocks/Microcart/ClearCartButton'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import Product from 'theme/components/core/blocks/Microcart/Product'
import SalesRuleOffer from 'theme/components/core/blocks/Microcart/SalesRuleOffer'
import ShareCart from 'src/modules/vsf-sharecart/components/ShareCart'
import MicrocartBanners from 'theme/components/core/blocks/Custom/MicrocartBanners.vue'
import FooterTrustPaymentMethods from 'theme/components/core/blocks/Footer/FooterTrustPaymentMethods.vue'

import EditMode from './EditMode'
import { InstantCheckoutModule } from 'src/modules/instant-checkout'

import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  components: {
    Logo,
    Product,
    ClearCartButton,
    ButtonFull,
    ButtonOutline,
    BaseInput,
    // InstantCheckout,
    SalesRuleOffer,
    ShareCart,
    MicrocartBanners,
    FooterTrustPaymentMethods
  },
  mixins: [
    VueOfflineMixin,
    EditMode,
    onEscapePress
  ],
  data () {
    return {
      addCouponPressed: false,
      couponCode: '',
      componentLoaded: false,
      isInstantCheckoutRegistered: isModuleRegistered('InstantCheckoutModule'),
      isCollapse: false,
      cartUpdating: false
    }
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  beforeCreate () {
    registerModule(InstantCheckoutModule)
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
    })
    EventBus.$on('cart-loaded', () => {
      this.cartUpdating = false
    })
    EventBus.$on('cart-loading', () => {
      this.cartUpdating = true
    })
  },
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      appliedCoupon: 'cart/getCoupon',
      totals: 'cart/getTotals',
      isOpen: 'cart/getIsMicroCartOpen',
      storeConfig: 'storeConfig/getConfigValue'
    }),
    ...mapState({
      platformTotals: state => state.cart.platformTotals
    }),
    isCheckoutDisabled () {
      if (!this.storeConfig('sales/minimum_order/active')) {
        return false;
      }

      const amount = this.productsInCart
        .filter(p => p.attribute_set_id === 12)
        .map(p => p.price_incl_tax * p.qty)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }, 0);
      if (amount === 0) {
        return false
      }
      if (amount < parseFloat(this.storeConfig('sales/minimum_order/amount'))) {
        return true
      }
      return false;
    },
    taxTotalsTitle () {
      const taxTotal = this.platformTotals
        .total_segments
        .find((segment) => segment.code === 'tax');

      if (!taxTotal) {
        return false;
      }

      const tax_grandtotal_details = taxTotal.extension_attributes
        .tax_grandtotal_details
        .find((details) => true)
      if (!tax_grandtotal_details) {
        return false
      }

      const tax = tax_grandtotal_details.rates
        .find((rates) => true)

      if (tax) {
        return 'inkl. ' + tax.percent + '% MwSt.'
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    addDiscountCoupon () {
      this.addCouponPressed = true
    },
    clearCoupon () {
      this.$store.dispatch('cart/removeCoupon')
      this.addCouponPressed = false
    },
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart')
    },
    async setCoupon () {
      this.applyCoupon(this.couponCode).then(() => {
        this.addCouponPressed = false
        this.couponCode = ''
      }).catch(() => {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t("You've entered an incorrect coupon code. Please try again."),
          action1: { label: i18n.t('OK') }
        })
      })
    },
    toggleSpecial () {
      this.isCollapse = !this.isCollapse;
    },
    closeMicrocartExtend () {
      this.toggleMicrocart()
      this.$store.commit('ui/setSidebar', false)
      this.addCouponPressed = false
    },
    onEscapePress () {
      this.toggleMicrocart()
    },
    clearCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('Are you sure you would like to remove all the items from the shopping cart?'),
        action1: { label: i18n.t('Cancel'), action: 'close' },
        action2: {
          label: i18n.t('OK'),
          action: async () => {
            // We just need to clear cart on frontend and backend.
            // but cart token can be reused
            await this.$store.dispatch('cart/clear', { disconnect: false })
          }
        },
        hasNoTimeout: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/animations/transitions";

  .close {
    i {
      opacity: 0.6;
    }
    &:hover,
    &:focus {
      i {
        opacity: 1;
      }
    }
  }

  .mt0 {
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  .clearcart {
    &-col {
      display: flex;
      align-self: center;
    }
  }

  .products {
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }

  .actions {
    @media (max-width: 767px) {
      padding: 0 15px;
    }
    .link {
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 20px 70px;
        &.checkout {
          margin-top: 55px;
          padding: 0;
        }
      }
    }
  }

  .summary {
    @media (max-width: 767px) {
      padding:  0 15px;
      font-size: 12px;
    }
  }

  .summary-heading {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-label {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-value {
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .delete-button {
    vertical-align: middle;
  }

  .coupon-wrapper {
    display: flex;

    .button-outline {
      text-transform: inherit;
      width: 50%;
    }

    .coupon-input {
      margin-right: 20px;
      width: 100%;
    }
  }

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    height: 100%;
    background:rgba(0, 0, 0, 0.4);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
